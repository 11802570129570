import { clamp } from "tsparticles-engine";
function checkDestroy(particle, value, minValue, maxValue) {
    switch (particle.options.size.animation.destroy) {
        case "max":
            if (value >= maxValue) {
                particle.destroy();
            }
            break;
        case "min":
            if (value <= minValue) {
                particle.destroy();
            }
            break;
    }
}
export function updateSize(particle, delta) {
    const data = particle.size;
    if (particle.destroyed ||
        !data ||
        !data.enable ||
        ((data.maxLoops ?? 0) > 0 && (data.loops ?? 0) > (data.maxLoops ?? 0))) {
        return;
    }
    const sizeVelocity = (data.velocity ?? 0) * delta.factor, minValue = data.min, maxValue = data.max, decay = data.decay ?? 1;
    if (!data.time) {
        data.time = 0;
    }
    if ((data.delayTime ?? 0) > 0 && data.time < (data.delayTime ?? 0)) {
        data.time += delta.value;
    }
    if ((data.delayTime ?? 0) > 0 && data.time < (data.delayTime ?? 0)) {
        return;
    }
    switch (data.status) {
        case "increasing":
            if (data.value >= maxValue) {
                data.status = "decreasing";
                if (!data.loops) {
                    data.loops = 0;
                }
                data.loops++;
            }
            else {
                data.value += sizeVelocity;
            }
            break;
        case "decreasing":
            if (data.value <= minValue) {
                data.status = "increasing";
                if (!data.loops) {
                    data.loops = 0;
                }
                data.loops++;
            }
            else {
                data.value -= sizeVelocity;
            }
    }
    if (data.velocity && decay !== 1) {
        data.velocity *= decay;
    }
    checkDestroy(particle, data.value, minValue, maxValue);
    if (!particle.destroyed) {
        data.value = clamp(data.value, minValue, maxValue);
    }
}
